import AppsIcon from '@mui/icons-material/Apps'
import ListIcon from '@mui/icons-material/List'
import { ButtonGroup } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import Breadcrumb from './breadcrumb'
import { Container } from './container'
import { PageContext } from './context'
import NewFolderDialog from './dialogs/new-folder-dialog'
import RemoveFolderDialog from './dialogs/remove-folder-dialog'
import SelectFolderDialog from './dialogs/select-folder-dialog'
import ShareFolderDialog from './dialogs/share-folder-dialog'
import GridView from './grid-view'
import ListView from './list-view'
import MultiSelectToolbar from './multi-select-toolbar/intex'
import SearchBox from './search-box'
import SearchFilters from './search-filters'

import { Button } from '@/components/button'
import DateRangePicker from '@/components/date-range-picker'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { showBanner } from '@/store/controls'
import { useVideoExcerpts, useVideoExcerptsFromPublicLibrary } from '@/store/library'
import { useAlertBanner } from '@/store/providers/alert-banner-provider'
import { isProd } from '@/utils'
import { BANNER_KEYS } from '@/utils/banners'
import cls from '@/utils/classnames'
import { nestFolders } from '@/utils/helpers/nest-folders'

const VIEW = {
  LIST: 'list',
  GRID: 'grid'
}

const DEFAULT_VIEW = VIEW.GRID

const SHOW_SEARCH_FILTERS = false

export default function LibraryPage () {
  const params = useParams()

  const fid = params?.fid || false
  const uid = params.uid

  if (uid) {
    return <PublicLibraryPage fid={fid} uid={uid} />
  }
  return <MyLibraryPage />
}

function PublicLibraryPage ({ fid, uid }) {
  const data = useVideoExcerptsFromPublicLibrary({ fid, uid })

  return <PublicLibraryPageWithData data={data} />
}

function MyLibraryPage () {
  const data = useVideoExcerpts()

  return <LibraryPageWithData data={data} />
}

function LibraryPageWithData ({ data }) {
  const { error, isReady, videoExcerpts, folders, isRootFolderPublic = false } = data
  const [view, setView] = useState(VIEW.GRID)
  const { fid } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const isMobile = useMobileDetect()
  const isDesktop = useMobileDetect('desktop')
  const [filteredVideoExcerpts, setFilteredVideoExcerpts] = useState([])
  const inlineVideoEditRef = useRef({})
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [dateRange, setDateRange] = useState([])
  const [nestedFolders, setNestedFolders] = useState([])
  const [newFolderDialogIsOpen, setNewFolderDialogIsOpen] = useState(false)
  const [moveItemsIsOpen, setIsMoveItemsOpen] = useState(false)
  const [isRemoveDialogIsOpen, setIsRemoveDialogOpen] = useState(false)
  const [isShareDialogIsOpen, setIsShareDialogOpen] = useState(false)
  const [itemsForMoving, setItemsForMoving] = useState([])
  const [bulkSelected, setBulkSelected] = useState([])
  const lastClicked = useRef(null)
  const toolbarPositionRef = useRef(null)

  const isBulkSelecting = Boolean(bulkSelected?.length)

  const context = useMemo(
    () => ({
      error,
      isLoading: !isReady,
      view,
      setView,
      videoExcerpts,
      folders,
      nestedFolders,
      filteredVideoExcerpts,
      setIsRemoveDialogOpen,
      setIsShareDialogOpen,
      isRemoveDialogIsOpen,
      setNewFolderDialogIsOpen,
      setFilteredVideoExcerpts,
      dateRange,
      setDateRange,
      setIsMoveItemsOpen,
      moveItemsIsOpen,
      setItemsForMoving,
      isBulkSelecting,
      bulkSelected,
      setBulkSelected,
      lastClicked,
      itemsForMoving,
      setSelectedFolder,
      selectedFolder,
      isRootFolderPublic,
      inlineVideoEditRef,
      isInPublicLibrary: false
    }),
    [error, isReady, view, videoExcerpts, folders, nestedFolders, filteredVideoExcerpts, isRemoveDialogIsOpen, dateRange, moveItemsIsOpen, bulkSelected, isBulkSelecting, lastClicked, itemsForMoving, selectedFolder, isRootFolderPublic]
  )

  function onCreateNewFolder () {
    setNewFolderDialogIsOpen(true)
  }

  useEffect(() => {
    // This code will run every time the route changes
    setBulkSelected([])
  }, [location])

  useEffect(() => {
    if (!isProd) {
      dispatch(showBanner(BANNER_KEYS.DEV_ENV))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (folders) {
      const nestedFolders = nestFolders(folders)

      setNestedFolders(nestedFolders)
    }
  }, [folders])

  useEffect(() => {
    setSelectedFolder(fid || null)
  }, [fid])

  return (
    <PageContext.Provider value={context}>
      <Container className={clsx([{ mobile: isMobile, desktop: isDesktop }])}>
        <div className='inner-container'>
          <div className='page-header'>
            <div className='top-header'>
              <Breadcrumb />
              <ViewButtons view={view} setView={setView} />
            </div>
            <div className='controls-header'>
              <div className='search-section'>
                <SearchBox />
                <DateRangePicker {...{ dateRange, setDateRange }} />
              </div>
              {!isMobile && <Button onClick={onCreateNewFolder} className='gray no-outline no-wrap'>+ New Folder</Button>}
            </div>
            {SHOW_SEARCH_FILTERS && <SearchFilters />}
          </div>
          {isMobile && <Button onClick={onCreateNewFolder} className='gray no-outline no-wrap new-folder-button'>+ New Folder</Button>}
          <div ref={toolbarPositionRef} className={cls('multiselect-container', isBulkSelecting && 'active')}>
            {isBulkSelecting &&
              <MultiSelectToolbar positionRef={toolbarPositionRef} fid={fid} />}
          </div>
          <div className='page-content'>
            {view === 'list' && <ListView />}
            {view === 'grid' && <GridView />}
          </div>
        </div>
        <NewFolderDialog isOpen={newFolderDialogIsOpen} setIsOpen={setNewFolderDialogIsOpen} filteredVideoExcerpts={filteredVideoExcerpts} />
        <SelectFolderDialog />
        <RemoveFolderDialog isOpen={isRemoveDialogIsOpen} setIsOpen={setIsRemoveDialogOpen} />
        <ShareFolderDialog folder={isShareDialogIsOpen} setIsOpen={setIsShareDialogOpen} />
      </Container>
    </PageContext.Provider>
  )
}

function PublicLibraryPageWithData ({ data }) {
  const { error, isReady, videoExcerpts, folders, isRootFolderPublic = false } = data
  const [view, setView] = useState(DEFAULT_VIEW)
  const { fid } = useParams()
  const isMobile = useMobileDetect()
  const isDesktop = useMobileDetect('desktop')
  const { showAlert } = useAlertBanner()
  const [filteredVideoExcerpts, setFilteredVideoExcerpts] = useState([])
  const inlineVideoEditRef = useRef({})
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [dateRange, setDateRange] = useState([])
  const [nestedFolders, setNestedFolders] = useState([])

  const context = useMemo(
    () => ({
      error,
      isLoading: !isReady,
      view,
      setView,
      videoExcerpts,
      folders,
      nestedFolders,
      filteredVideoExcerpts,
      setFilteredVideoExcerpts,
      dateRange,
      setDateRange,
      setSelectedFolder,
      selectedFolder,
      isRootFolderPublic,
      inlineVideoEditRef,
      isInPublicLibrary: true
    }),
    [dateRange, error, filteredVideoExcerpts, folders, isReady, isRootFolderPublic, nestedFolders, selectedFolder, videoExcerpts, view]
  )

  useEffect(() => {
    if (error) {
      showAlert({
        permanent: true,
        dismissibleOnMobile: false,
        title: 'Access Denied',
        message: 'Sorry, this library is now private.'
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (folders) {
      const nestedFolders = nestFolders(folders)

      setNestedFolders(nestedFolders)
    }
  }, [folders])

  useEffect(() => {
    setSelectedFolder(fid || null)
  }, [fid])

  if (error) {
    return <></>
  }

  return (
    <PageContext.Provider value={context}>
      <Container className={clsx([{ mobile: isMobile, desktop: isDesktop }])}>
        <div className='inner-container'>
          <div className='page-header'>
            <div className='top-header'>
              <Breadcrumb />
            </div>
            <div className='controls-header'>
              <div className='search-section'>
                <SearchBox />
                <DateRangePicker {...{ dateRange, setDateRange }} />
              </div>
            </div>
            {SHOW_SEARCH_FILTERS && <SearchFilters />}
          </div>
          <div className='page-content'>
            {view === 'list' && <ListView />}
            {view === 'grid' && <GridView />}
          </div>
        </div>
      </Container>
    </PageContext.Provider>
  )
}

function ViewButtons (props) {
  const { view, setView } = props

  return (
    <ButtonGroup
      className='view-button-group'
      disableElevation
      variant='contained'
    >
      <Button
        className={view === VIEW.LIST ? 'selected' : 'unselected'}
        onClick={() => setView(VIEW.LIST)}
      >
        <ListIcon className='icon' />
      </Button>
      <Button
        className={view === VIEW.GRID ? 'selected' : 'unselected'}
        onClick={() => setView(VIEW.GRID)}
      >
        <AppsIcon className='icon' />
      </Button>
    </ButtonGroup>
  )
}
