import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { useState } from 'react'

import DownloadRawDataModal from '../raw-data-dialog'

import { Container } from './container'

import { useGetVideo } from '@/store/video'
import cls from '@/utils/classnames'
import { downloadOrOpenLink } from '@/utils/download'

/**
 * Data component, showing access to advanced stats.
 *
 * @exports
 * @param props {object} { insights, vid }
 * @returns {React.ReactElement}
 */
export function Data (props) {
  const { insights, vid } = props

  const videoDetails = useGetVideo(vid)
  const aiEngineVersion = videoDetails?.data?.workflows?.[0]?.aiEngineVersion

  const [showRawDataDialog, setShowRawDataDialog] = useState(false)

  if (!insights) {
    return 'Loading...'
  }

  const handleDownloadExcelData = () => {
    const file = `${aiEngineVersion}/${vid}_${aiEngineVersion}_stats.xlsx`
    const url = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${file}`
    downloadOrOpenLink(url, file, true)
  }

  const handleDownloadRawData = () => {
    setShowRawDataDialog(true)
  }

  return (
    <Container className={cls('data', props.className)}>
      <div className='text-container'>
        <div className='title'>
          For data nerds only 🤓
        </div>
        <div className='body'>
          Access advanced stats! Download an Excel or Raw data file for any video. Join our dev community and read more <a target='_blank' rel='noopener noreferrer' href='https://roadmap.pb.vision/help/articles/2010377-advanced-insights-with-pb-visions-data-exports'>here</a>.
        </div>
      </div>
      <div className='buttons'>
        <div className='download-container' onClick={handleDownloadExcelData}>
          <TextSnippetIcon className='icon' />
          <div className='text'>
            Download Excel Data
          </div>
        </div>
        <div className='download-container' onClick={handleDownloadRawData}>
          <TextSnippetIcon className='icon' />
          <div className='text'>
            Download Raw Data
          </div>
        </div>
      </div>
      {showRawDataDialog && <DownloadRawDataModal open={showRawDataDialog} onClose={() => setShowRawDataDialog(false)} vid={vid} />}
    </Container>
  )
}
