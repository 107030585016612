import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useContext, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ListViewContainer, TableBodyContainer, TableSortLabel } from './container'

import { Spinner } from '@/components/spinner'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { PageContext } from '@/pages/library/context'
import { displayItemsByFolder } from '@/pages/library/display-items-by-folder'
import Folder from '@/pages/library/list-view/folder'
import VideoThumb from '@/pages/library/list-view/video-thumb'

function DisplayLibraryItem (item) {
  if (item?.vid) {
    return <VideoThumb videoExcerpt={item} />
  }
  if (item?.fid) {
    return <Folder {...item} />
  }
}

/**
 * ListView component
 *
 * @exports
 * @returns {React.ReactElement} ListView component
 */
function ListView () {
  const { fid } = useParams()
  const containerRed = useRef()

  const { filteredVideoExcerpts, isLoading } = useContext(PageContext)
  const isMobile = useMobileDetect()

  const [order, setOrder] = useState('asc') // "asc" or "desc"
  const [orderBy, setOrderBy] = useState('name') // Column to sort by

  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const renderItems = useMemo(() => {
    const filteredItems = displayItemsByFolder(filteredVideoExcerpts, fid)
    return filteredItems.sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy].localeCompare(b[orderBy])
      } else {
        return b[orderBy].localeCompare(a[orderBy])
      }
    })
  }, [fid, filteredVideoExcerpts, orderBy, order])

  return (
    <>
      <ListViewContainer ref={containerRed} className={isMobile && 'mobile'}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='table-cell name'>
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleSortRequest('name')}
                    IconComponent={ExpandMoreIcon}
                  >
                    <div className='table-heading name'>
                      Name
                    </div>
                  </TableSortLabel>
                </TableCell>
                <TableCell className='table-cell date'>
                  <div className='table-heading date'>
                    Date
                  </div>
                </TableCell>
                {!isMobile &&
                  <TableCell className='table-cell duration'>
                    <div className='table-heading'>
                      Duration
                    </div>
                  </TableCell>}
              </TableRow>
            </TableHead>
            <TableBodyContainer>
              {renderItems.map((row, i) => (
                <DisplayLibraryItem key={i} {...row} />
              ))}
            </TableBodyContainer>
          </Table>
        </TableContainer>
      </ListViewContainer>
      <Spinner hide={!isLoading} text='Loading library' />
    </>
  )
}

export default ListView
