import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import emailValidator from 'email-validator'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useState } from 'react'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'

import styles from './styles.module.scss'

import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { normalizeSendEmailErrorMessage } from '@/utils/email'

function ForgotPassword () {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [email, setEmail] = useState(searchParams.get('email'))
  const [isProcessingUserRequest, setIsProcessingUserRequest] = useState(false)
  const openSnackbar = useSnackbar()
  const { validate: validateEmail } = emailValidator
  const emailValid = validateEmail(email)
  const matchUpload = useMatch('/anonymous-upload')

  function wrapFirebaseAuthRequest (promise) {
    setIsProcessingUserRequest(true)
    promise
      .then(() => {
        if (matchUpload) {
          searchParams.delete('forgot')
          setSearchParams({ ...searchParams, forgotSent: 1 }, { replace: true })
        } else {
          navigate('/login?' + new URLSearchParams({ from: 'reset-password', email }).toString())
        }
      })
      .catch((err) => {
        const normalizedError = normalizeSendEmailErrorMessage(err)
        openSnackbar(normalizedError, 'error')
      })
      .finally(() => {
        setIsProcessingUserRequest(false)
      })
  }

  const doSendPasswordResetEmail = () => {
    wrapFirebaseAuthRequest(
      sendPasswordResetEmail(getAuth(), email, {
        url: window.location.href
      })
    )
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter' && email) {
      doSendPasswordResetEmail()
    }
  }

  const handleBackToLogin = () => {
    if (matchUpload) {
      searchParams.delete('forgot')
      setSearchParams({ ...searchParams }, { replace: true })
    } else {
      navigate('/login?' + new URLSearchParams({ email }).toString())
    }
  }

  return (
    <div className={clsx([styles.forgotPassword, { [styles.mobile]: isMobile }])}>
      <div className={styles.content}>
        <div className={styles.title}>Forgot your password?</div>
        <div className={styles.info}>
          {`No Problem! Enter the email address
          associated with your account and we'll
          send you a link to reset your password.`}
        </div>
        <div className={clsx([styles.emailField])}>
          <div className={styles.label}>Email</div>
          <TextField
            autoFocus={!isMobile}
            variant='outlined'
            classes={{ root: styles.muiRoot }}
            value={email || ''}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            placeholder=''
            inputProps={{ onKeyUp: onEmailKeyUp }}
            disabled={isProcessingUserRequest}
          />
        </div>
        <Button
          classes={{ root: styles.sendEmailButton, disabled: styles.disabled }}
          disabled={!emailValid}
          onClick={doSendPasswordResetEmail}
        >
          Send Email
        </Button>
        <div className={styles.backToLogin} onClick={handleBackToLogin}>
          {matchUpload ? 'Go back' : 'Back to login'}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
