import { TableCell } from '@mui/material'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { PageContext } from '../../context'
import VideoContextMenu from '../../grid-view/video-thumb/video-context-menu'
import { TableRowContainer } from '../container'

import { CountdownRemaining } from '@/components/CountdownRemaining'
import OurDateTimePicker from '@/components/datetime-picker'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsLoggedIn } from '@/store/auth'
import { showBanner } from '@/store/controls'
import { setVideoNameInLibraryOnly, useUnseenVIDs } from '@/store/library'
import { useAllLatestProcessingProgress } from '@/store/processing'
import { setVideoField } from '@/store/video'
import { BANNER_KEYS } from '@/utils/banners'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

function VideoThumb ({ videoExcerpt }) {
  const { secs, name, epoch, vid } = videoExcerpt
  const allProcessingProgress = useAllLatestProcessingProgress()
  const isMobile = useMobileDetect()
  const [editNameValue, setEditNameValue] = useState(name)
  const [editDateValue, setEditDateValue] = useState(epoch * 1000)
  const [isEditingVideoName, setIsEditingVideoNameInState] = useState(false)
  const [isEditingVideoDate, setIsEditingVideoDateInState] = useState(false)
  const containerRef = useRef(null)
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  const { inlineVideoEditRef } = useContext(PageContext)

  const setIsEditingVideoName = useCallback(newValue => {
    if (newValue && !isLoggedIn) {
      // prompt user to login so they can edit the name
      dispatch(showBanner(BANNER_KEYS.LOGIN_TO_FINISH_ACTION))
    } else {
      setIsEditingVideoNameInState(newValue)
    }
  }, [dispatch, isLoggedIn])

  const setIsEditingVideoDate = useCallback(newValue => {
    if (newValue && !isLoggedIn) {
      // prompt user to login so they can edit the date
      dispatch(showBanner(BANNER_KEYS.LOGIN_TO_FINISH_ACTION))
    } else {
      setIsEditingVideoDateInState(newValue)
    }
  }, [dispatch, isLoggedIn])

  const unseenVIDsResult = useUnseenVIDs()

  useEffect(() => {
    const contextVideoEdit = inlineVideoEditRef?.current
    contextVideoEdit && Object.assign(contextVideoEdit, {
      vid,
      editNameValue,
      editDateValue,
      isEditingVideoName,
      isEditingVideoDate,
      setIsEditingVideoName,
      setIsEditingVideoDate,
      updateVideoDate
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNameValue, editDateValue, isEditingVideoName, isEditingVideoDate])

  const duration = (() => {
    if (typeof secs === 'undefined') return ''
    const minutes = Math.floor(secs / 60)
    const rem = secs - minutes * 60
    const minutesDisplay = String(minutes).padStart(2, '0')
    const secondsDisplay = String(rem).padStart(2, '0')

    if (minutes < 1) {
      return `${secondsDisplay}s`
    }

    return `${minutesDisplay}m ${secondsDisplay}s`
  })()

  const onNameClick = () => {
    const contextVideoEdit = inlineVideoEditRef.current
    const {
      isEditingVideoName: contextIsEditingVideoName,
      isEditingVideoDate: contextIsEditingVideoDate,
      vid: contextVid
    } = contextVideoEdit

    if (contextIsEditingVideoDate) {
      contextVideoEdit.setIsEditingVideoDate?.(false)
      return
    }

    if (contextIsEditingVideoName && contextVid !== vid) {
      contextVideoEdit.setIsEditingVideoName(false)
    } else {
      setEditNameValue(name)
      setIsEditingVideoName(true)
    }
  }

  const onDateClick = () => {
    const contextVideoEdit = inlineVideoEditRef.current
    const {
      isEditingVideoName: contextIsEditingVideoName,
      isEditingVideoDate: contextIsEditingVideoDate,
      vid: contextVid
    } = contextVideoEdit

    if (contextIsEditingVideoName) {
      contextVideoEdit.setIsEditingVideoName?.(false)
      return
    }

    if (contextIsEditingVideoDate && contextVid !== vid) {
      contextVideoEdit.setIsEditingVideoDate(false)
    } else {
      setEditDateValue(epoch * 1000)
      setIsEditingVideoDate(true)
    }
  }

  const onChangeVideoName = (event) => {
    const newValue = event.target.value
    setEditNameValue(newValue)
  }

  const onChangeVideoDate = (value) => {
    if (value) {
      const newValue = value.toDate().valueOf()
      if (!isNaN(newValue)) {
        setEditDateValue(newValue)
        updateVideoDate(newValue)
        setIsEditingVideoDate(false)
      }
    }
  }

  const updateVideoName = useCallback(async (newName) => {
    // assuming the video is owned by us; it's possible we've added other
    // people's videos to our library
    if (videoExcerpt.notMine) {
      dispatch(setVideoNameInLibraryOnly(vid, newName))
    } else {
      dispatch(setVideoField(vid, 'name', newName))
    }
  }, [dispatch, vid, videoExcerpt.notMine])

  const updateVideoDate = useCallback(async (newTimestamp) => {
    if (isNaN(newTimestamp)) return

    if (!videoExcerpt.notMine) {
      const newEpoch = newTimestamp / 1000
      dispatch(setVideoField(vid, 'gameStartEpoch', newEpoch))
    }
  }, [dispatch, vid, videoExcerpt.notMine])

  // Apply changes to name or date when clicking away
  useEffect(() => {
    const listener = (event) => {
      if (isEditingVideoName && event.target.id !== 'inline-edit-video-name') {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        setIsEditingVideoName(false)
        updateVideoName(editNameValue)
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [
    editNameValue,
    isEditingVideoName,
    updateVideoName,
    editDateValue,
    isEditingVideoDate,
    updateVideoDate,
    containerRef,
    setIsEditingVideoName,
    setIsEditingVideoDate
  ])

  const renderNameSection = () => (
    <div className='name-section'>
      {unseenVIDsResult.unseenVIDs.has(videoExcerpt.vid) && (
        <div className='red-dot' />
      )}
      <div className='name-container'>
        {isEditingVideoName && (
          // To catch the Android 'Go' button, we need parent form as it triggers the submit event
          <form onSubmit={() => updateVideoName(editNameValue)} style={{ width: '100%' }}>
            <TextField
              autoFocus
              id='inline-edit-video-name'
              value={editNameValue}
              onChange={onChangeVideoName}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onKeyDown={async (key) => {
                if (key.code === 'Enter') {
                  setIsEditingVideoName(false)
                  await updateVideoName(editNameValue)
                } else if (key.code === 'Escape') {
                  setIsEditingVideoName(false)
                }
              }}
              onBlur={() => updateVideoName(editNameValue)}
              onFocus={event => {
                event.target.select()
              }}
              sx={{
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: 400,
                lineHeight: '24px',
                width: '100%'
              }}
            />
          </form>
        )}
        {!isEditingVideoName && (isProcessing
          ? (
            <div style={{ display: 'flex', gap: '5px' }}>
              <p>AI Processing...</p>
              <p style={{ color: COLORS['neutral-400'] }}>about <CountdownRemaining epoch={processingProgress.estimatedFinishEpoch} /></p>
            </div>
            )
          : (
            <div className='static-name-section'>
              <div className='name'>{name}</div>
            </div>
            )
        )}
      </div>
    </div>

  )

  const renderDateSection = () => (
    <div className='table-text date'>
      <div
        className='date-section'
        onClick={(e) => {
          if (isEditingVideoDate) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        {isEditingVideoDate && (
          // To catch the Android 'Go' button, we need parent form as it triggers the submit event
          <form onSubmit={() => updateVideoDate(editDateValue)}>
            <OurDateTimePicker
              format='MM/DD/YYYY'
              value={dayjs(editDateValue)}
              onAccept={(val) => onChangeVideoDate(val)}
              onCancel={() => setIsEditingVideoDate(false)}
            />
          </form>
        )}
        {!isEditingVideoDate && !!editDateValue && (
          <div className='date'>
            {dayjs(editDateValue).format('ddd, DD MMM')}
          </div>
        )}
        {isMobile &&
          <VideoContextMenu
            className='menu-button'
            videoExcerpt={videoExcerpt}
            isProcessing={isProcessing}
            onDateClick={onDateClick}
            onNameClick={onNameClick}
          />}
      </div>
    </div>
  )

  const processingProgress = allProcessingProgress[videoExcerpt.vid]
  const isProcessing = processingProgress && processingProgress.percentDone < 1

  useEffect(() => {
    // Update date value once it's provided by props but only if user isn't currently editing it
    if (!isEditingVideoDate && epoch) {
      setEditDateValue(epoch * 1000)
    }
  }, [epoch, isEditingVideoDate])

  return (
    <TableRowContainer
      id='MASUK'
      className={cls('video', isMobile && 'mobile')}
      component={Link}
      ref={containerRef}
      to={`/video/${vid}`}
      data-vid={vid}
      onClick={(event) => {
        const contextVideoEdit = inlineVideoEditRef.current

        if (contextVideoEdit.isEditingVideoName) {
          contextVideoEdit.setIsEditingVideoName(false)
          event.preventDefault()
          event.stopPropagation()
        }
        if (isEditingVideoDate) {
          contextVideoEdit.setIsEditingVideoDate(false)
          event.preventDefault()
          event.stopPropagation()
        }
      }}
    >
      <TableCell className='table-cell name'>
        {renderNameSection()}
      </TableCell>
      <TableCell className='table-cell date'>
        {renderDateSection()}
      </TableCell>
      {!isMobile &&
        <TableCell className='table-cell duration'>
          <div className='table-text duration'>
            {duration}
            <VideoContextMenu
              className='menu-button'
              videoExcerpt={videoExcerpt}
              isProcessing={isProcessing}
              onDateClick={onDateClick}
              onNameClick={onNameClick}
            />
          </div>
        </TableCell>}
    </TableRowContainer>
  )
}

export default VideoThumb
