import DownloadIcon from '@mui/icons-material/Download'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import InfoIcon from '@mui/icons-material/Info'
import MoreVertIcon from '@mui/icons-material/MoreVert'
// import ShareIcon from '@mui/icons-material/Share'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { Menu, MenuItem, ListItemIcon, InputBase, SvgIcon } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams, useMatch } from 'react-router-dom'

import FeatureGuard from '../guards/feature-guard'
import { CircularLoadingIndicator } from '../loading-indicator/CircularLoadingIndicator'
import DownloadRawDataModal from '../raw-data-dialog'

import ExcelIcon from '@/assets/icons/excel-icon.svg?react'
import { Button } from '@/components/button'
import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { diffFilters } from '@/pages/home/explorer/helpers'
import { PageContext } from '@/pages/library/context'
import SelectFolderDialog from '@/pages/library/dialogs/select-folder-dialog'
import { useLoggedInUser } from '@/store/auth'
import { showBanner } from '@/store/controls'
import { useVideoExcerpts } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { useShotFilters } from '@/store/shot-filter'
import { setVideoField, useGetVideo, useMayEditVideo } from '@/store/video'
import { isProd } from '@/utils'
import { APIContext } from '@/utils/api'
import { BANNER_KEYS } from '@/utils/banners'
import COLORS from '@/utils/colors'
import { downloadOrOpenLink } from '@/utils/download'
import { column, row } from '@/utils/flexGrid'
import { copyToClipboard, formatDate, formatWithTimeOnly, formatWithWeekAndDate } from '@/utils/helper'
import { nestFolders } from '@/utils/helpers/nest-folders'
import { isInMobileAppWebview, notifyShareRequested } from '@/utils/mobile-app-communication'
import theme from '@/utils/theme'
import { getVideoURLForDownload } from '@/utils/video'

/**
 * TitleBar container
 *
 * @returns {React.ReactElement}
 */
const TitleToolbar = styled('div')({
  color: COLORS['neutral-800'],
  fontSize: 24,
  fontWeight: 600,

  '& .title-container': {
    ...row,
    justifyContent: 'space-between',
    width: '100%'
  },
  '& .title': {
    marginRight: 10,
    whiteSpace: 'wrap',
    flex: 1
  },
  '& .date': {
    ...row,
    alignItems: 'center',
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    gap: theme.spacing(1),
    '& p': {
      fontWeight: 600
    },
    '& b': {
      color: COLORS['neutral-400']
    },
    '& .date-divider': {
      backgroundColor: COLORS['neutral-400'],
      height: 24,
      width: 1
    }
  },
  '& #share-button': {
    minWidth: 88
  },
  '& form': {
    display: 'inline-flex',
    flex: 1,
    '& textarea': {
      fontSize: 24,
      fontWeight: 600,
      background: COLORS['neutral-050'],
      border: `2px solid ${COLORS['neutral-400']}`,
      borderRadius: 4,
      padding: 0
    }
  },
  '& .btn-grp': {
    minWidth: 300,
    backgroundColor: COLORS.white,
    paddingBlock: '8px',
    border: `1px solid ${COLORS['neutral-300']}`,
    color: COLORS['neutral-700'],
    '& svg': {
      color: 'inherit',
      marginRight: 4
    },
    '&:hover': {
      borderColor: COLORS['neutral-400']
    },
    '&.insights': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    '&.explorer': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    '&.selected': {
      borderColor: `1px solid ${COLORS['neutral-500']}`,
      color: COLORS.white,
      backgroundColor: COLORS['primary-500']
    }
  },
  '& .cond-wrap': {
    flexGrow: 1,
    width: '100%',
    overflow: 'hidden'
  },
  '& .additional-controls': {
    ...row,
    alignSelf: 'flex-end'
  },

  [theme.breakpoints.up('lg')]: {
    '& .container': {
      borderBottom: `1px solid ${COLORS['neutral-300']}`,
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  [theme.breakpoints.down('lg')]: {
    '& form': {
      width: '100%',
      '& textarea': {
        fontSize: 18
      }
    },
    '& .container': {
      ...column,
      gap: theme.spacing(1),
      alignItems: 'flex-start'
    },

    '& .date .additional-controls': {
      display: 'flex'
    }

  },
  [theme.breakpoints.down('md')]: {
    '& .additional-controls': {
      flexGrow: 0,
      '& button.MuiButtonBase-root': {
        minHeight: 32
      }
    }
  }
})

// Workaround for eslint issue with multiline-ternary / react/jsx-closing-tag-location and react/jsx-curly-newline
const IfElse = (props) => {
  return props.condition ? props.children : props.fallback
}

const ConditionalWrap = (props) => {
  return props.condition ? <div className='cond-wrap'>{props.children}</div> : props.children
}

export function useMenu () {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const openMenu = (event) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return { anchorEl, isOpen, openMenu, closeMenu }
}

/**
 * Top Insights title bar, showing date / game name and share / download menus
 *
 * @exports
 * @param props {object} {date, name}
 * @returns {React.ReactElement}
 */

let shareURL = ''
export function TitleBar () {
  const { workflow, video } = useContext(APIContext)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const openSnackbar = useSnackbar()
  const { nestParamsIntoURL } = useURLParams()
  const isMobile = useMobileDetect()
  // const isWebView = isInMobileAppWebview()
  const params = useParams()
  const actionMenu = useMenu()
  const isExplorer = useMatch('/video/:vid/explore')
  const { vid } = params
  const filters = useShotFilters()
  const mayEdit = useMayEditVideo(vid)
  const user = useLoggedInUser()
  const [isEditingVideoName, setIsEditingVideoNameInState] = useState(false)
  const [showRawDataDialog, setShowRawDataDialog] = useState(false)
  const videoDetails = useGetVideo(vid)
  const isWorkflowDone = Boolean(workflow?.epochFinished)

  const isDownloadEnabled = isWorkflowDone && workflow && !workflow.abort && !workflow.error && !workflow.noInsights

  const [itemsForMoving, setItemsForMoving] = useState([])
  const [moveItemsIsOpen, setIsMoveItemsOpen] = useState(false)
  const [nestedFolders, setNestedFolders] = useState([])
  const { folders, videoExcerpts } = useVideoExcerpts()

  const videoExcerpt = videoExcerpts.find(excerpt => excerpt.vid === vid)

  const name = videoExcerpt?.name ?? video.userData.name
  const date = formatDate(video.userData.gameStartEpoch, formatWithWeekAndDate, undefined, true)
  const time = formatDate(video.userData.gameStartEpoch, formatWithTimeOnly, undefined, true)

  const [editNameValue, setEditNameValue] = useState(name)

  useEffect(() => {
    shareURL = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    shareURL += `/video/share/${vid}`
  }, [isExplorer, searchParams, vid])

  const setIsEditingVideoName = useCallback(newValue => {
    if (newValue && !user) {
      // prompt user to login so they can edit the name
      dispatch(showBanner(BANNER_KEYS.LOGIN_TO_FINISH_ACTION))
    } else {
      setIsEditingVideoNameInState(newValue)
    }
  }, [dispatch, user])

  const addReferralToUrl = (url) => {
    const referral = user?.referrals?.myCode?.toUpperCase()
    return referral ? url.includes('?') ? url.replace('?', `?rf=${referral}&`) : `${url}?rf=${referral}` : url
  }

  // eslint-disable-next-line no-unused-vars
  const shareButtonHandler = (event) => {
    if (isInMobileAppWebview()) {
      notifyShareRequested(addReferralToUrl(nestParamsIntoURL(shareURL)))
    } else {
      copyLinkToClipboard()
    }
  }

  const onDownloadVideoHandler = () => {
    if (!isDownloadEnabled) return
    const url = getVideoURLForDownload(video)
    downloadOrOpenLink(url, '', true)
  }

  const copyLinkToClipboard = async () => {
    const url = isExplorer ? `${shareURL}?state=${encodeURI(JSON.stringify({ path: 'explore', ...diffFilters(filters) }))}` : nestParamsIntoURL(shareURL)
    copyToClipboard(addReferralToUrl(url))
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const updateVideoName = useCallback(async (newName) => {
    if (newName !== name) {
      // assuming the video is owned by us; it's possible we've added other people's videos to our library
      if (mayEdit) {
        dispatch(setVideoField(vid, 'name', newName))
      } else {
        // should we do this here ?
        // dispatch(setVideoNameInLibraryOnly(vid, newName))
      }
    }
  }, [name, mayEdit, dispatch, vid])

  const onChangeVideoName = (event) => {
    const newValue = event.target.value
    setEditNameValue(newValue)
  }

  const onNameClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsEditingVideoName(true)
  }

  const openRawDataDialog = () => {
    if (!isDownloadEnabled) return
    setShowRawDataDialog(true)
    actionMenu.closeMenu()
  }

  const openInfoLink = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const link = 'https://roadmap.pb.vision/help/articles/2010377-advanced-insights-with-pb-visions-data-exports'
    downloadOrOpenLink(link, null, true)
  }

  const downloadExcelStats = () => {
    if (!isDownloadEnabled) return

    const aiEngineVersion = videoDetails.data.workflows[0].aiEngineVersion
    const file = `${aiEngineVersion}/${vid}_${aiEngineVersion}_stats.xlsx`
    const url = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${file}`
    downloadOrOpenLink(url, file, true)
  }

  const onMoveVideoToFolder = () => {
    actionMenu.closeMenu()
    setIsMoveItemsOpen(true)
    setItemsForMoving(state => [...state, videoExcerpt])
  }

  // temporary here until we move it to a separate component
  // eslint-disable-next-line no-unused-vars
  const AdditionalControls = () => (
    <div className='additional-controls'>
      {/* <Button
        variant='outlined'
        color='midnight'
        className='neutral-outline'
        sx={{ alignSelf: 'center', marginLeft: 'auto' }}
        id='share-button'
        onClick={shareButtonHandler}
      >
        <em>Share</em>
        <ShareIcon />
      </Button> */}
      <Button
        variant='outlined'
        color='midnight'
        className='neutral-outline'
        sx={{
          alignSelf: 'center',
          marginLeft: '9px',
          minWidth: 'auto',
          borderRadius: '8px',
          padding: '2px 6px',
          '& svg': { marginLeft: 0, color: COLORS['neutral-500'] }
        }}
        id='action-button'
        aria-controls={actionMenu.isOpen ? 'action-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={actionMenu.isOpen ? 'true' : undefined}
        onClick={(e) => actionMenu.openMenu(e)}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id='action-menu'
        anchorEl={actionMenu.anchorEl}
        open={actionMenu.isOpen}
        onClose={() => actionMenu.closeMenu()}
        MenuListProps={{ 'aria-labelledby': 'action-button' }}
      >
        <FeatureGuard video={video} requiredSubscription={SUBSCRIPTION_PLANS.PREMIUM}>
          <MenuItem onClick={onDownloadVideoHandler} disabled={!isDownloadEnabled}>
            <ListItemIcon>
              <DownloadIcon fontSize='small' />
            </ListItemIcon>
            Download Video
          </MenuItem>
        </FeatureGuard>
        <FeatureGuard video={video} requiredSubscription={SUBSCRIPTION_PLANS.PREMIUM}>
          <MenuItem onClick={downloadExcelStats} disabled={!isDownloadEnabled}>
            <ListItemIcon>
              <SvgIcon fontSize='18' component={ExcelIcon} />
            </ListItemIcon>
            Download Stats (Excel)
            <Button
              className='info-link-btn'
              onClick={openInfoLink}
              sx={{
                margin: 0,
                padding: 0,
                minHeight: 'unset',
                minWidth: 'unset',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <InfoIcon />
            </Button>
          </MenuItem>
        </FeatureGuard>
        <FeatureGuard video={video} requiredSubscription={SUBSCRIPTION_PLANS.PREMIUM}>
          <MenuItem onClick={openRawDataDialog} disabled={!isDownloadEnabled}>
            <ListItemIcon>
              <TextSnippetIcon fontSize='small' />
            </ListItemIcon>
            Download Raw Data
          </MenuItem>
        </FeatureGuard>
        <MenuItem onClick={onMoveVideoToFolder} disabled={!isDownloadEnabled || !videoExcerpt}>
          <ListItemIcon>
            <DriveFileMoveIcon fontSize='small' />
          </ListItemIcon>
          Move
          {!videoExcerpt && <CircularLoadingIndicator size={12} />}
        </MenuItem>
      </Menu>
    </div>
  )

  // Dummy call as it's not needed here
  const setBulkSelected = useCallback(() => undefined, [])
  const context = useMemo(
    () => ({
      moveItemsIsOpen, setIsMoveItemsOpen, nestedFolders, itemsForMoving, setItemsForMoving, setBulkSelected
    }),
    [itemsForMoving, moveItemsIsOpen, nestedFolders, setBulkSelected]
  )

  useEffect(() => {
    if (folders) {
      const nestedFolders = nestFolders(folders)

      setNestedFolders(nestedFolders)
    }
  }, [folders])

  return (
    <PageContext.Provider value={context}>
      <TitleToolbar>
        <div className='container insights-row-grid '>
          <div className='title-container video-column without-padding transparent'>
            <ConditionalWrap condition={isMobile}>
              <IfElse condition={isEditingVideoName} fallback={<h3 className='title' onClick={onNameClick}>{name}</h3>}>
                <form onSubmit={() => updateVideoName(editNameValue)} className='title-form'>
                  <InputBase
                    autoFocus
                    className='title'
                    value={editNameValue}
                    multiline
                    onChange={onChangeVideoName}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onKeyDown={async (key) => {
                      if (key.code === 'Enter') {
                        setIsEditingVideoName(false)
                        await updateVideoName(editNameValue)
                      } else if (key.code === 'Escape') {
                        setIsEditingVideoName(false)
                      }
                    }}
                    onBlur={() => {
                      updateVideoName(editNameValue)
                      setIsEditingVideoName(false)
                    }}
                    onFocus={event => {
                      event.target.select()
                    }}
                  />
                </form>
              </IfElse>
            </ConditionalWrap>
          </div>
          <div className='date video-sidebar-column without-padding transparent'>
            {/* {isMobile ? null : <p className='body-md'><b>Game date: </b></p>} */}
            <p className='body-md'>
              {date ?? ''}
            </p>
            {time && <div className='date-divider' />}
            <p className='body-md'>
              {time ?? ''}
            </p>
            {AdditionalControls()}
          </div>
        </div>
        {showRawDataDialog && <DownloadRawDataModal open={showRawDataDialog} onClose={() => setShowRawDataDialog(false)} vid={vid} />}
      </TitleToolbar>
      <SelectFolderDialog />
    </PageContext.Provider>
  )
}
