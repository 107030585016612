import { Box, MenuItem, Select, SvgIcon, styled } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { useRalliesPlayersStats } from '../use-rallies-stats'

import HelpIcon from '@/assets/help.svg?react'
import ShotNumberIcon from '@/assets/icon-shot-number.svg?react'
import { Percentile } from '@/components/percentile'
import ShotsViewer from '@/components/shots-viewer'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { use3DViewerShots } from '@/hooks/use-shots-for-3d-viewer'
import { useFilteredShotsWithContext } from '@/hooks/use-shots-with-context'
import { useURLParams } from '@/hooks/use-url-params'
import { APIContext } from '@/utils/api'
import COLORS from '@/utils/colors'
import { isLikelyBadShotData, normalizeDirection } from '@/utils/court'
import { row, column } from '@/utils/flexGrid'
import { enterPictureInPicture } from '@/utils/pip'
import theme from '@/utils/theme'

const SHOTS_ARRAY = [{ value: 999, label: 'All shots' }, { value: 0, label: 'Serves' }, { value: 1, label: 'Returns' }, { value: 2, label: '3rd Shots' }, { value: 3, label: '4th Shots' }, { value: 4, label: '5th Shots' }]
export const SHOTS_ARRAY_SINGULAR = SHOTS_ARRAY.slice(1, -1).map(item => item.label.slice(0, -1))
const SHOTS_TYPES = [{ id: 'all', label: 'All Shots' }, { id: 'drop', label: 'Drops' }, { id: 'drive', label: 'Drives' }, { id: 'lob', label: 'Lobs' }]
const THIRD_SHOTS_TOOLTIPS = {
  all: 'Quality estimates how well a shot is executed. Choose a specific shot type from the dropdown and this tooltip will explain how quality is computed for that shot type.',
  drop: 'Quality estimates how well a shot is executed. Drops are evaluated based on how attackable the ball would be if the receiver was perfectly situated on their kitchen line.',
  drive: 'Quality estimates how well a shot is executed. Drives are evaluated based on how high over the net the ball flies (lower is better) and how deep in the court the ball lands.',
  lob: 'Quality estimates how well a shot is executed. Lobs are evaluated based on how deep in the court the ball lands (deeper is better).'
}

// const generateTotalShotsLabel = (count, shotNumber, shotType) => {
//   let label = 'Total '

//   if (shotType !== 'all') {
//     label += `${SHOTS_ARRAY[shotNumber - 1].slice(0, -1)} ${SHOTS_TYPES.find(shot => shot.id === shotType).label}`
//   } else {
//     label += SHOTS_ARRAY[shotNumber - 1]
//   }

//   label += `: ${count}`

//   return label
// }

const calculateQuality = (stats, selected) => {
  let total = 0
  const collected = selected.reduce((acc, cur) => {
    let now = acc
    if (stats[cur]) {
      stats[cur].forEach((bt) => {
        if (Object.prototype.hasOwnProperty.call(bt, 'quality')) {
          total += 1
          now += bt.quality
        }
      })
    }
    return now
  }, 0)
  return collected ? (collected / total).toFixed(1) * 100 : 0
}

const getStats = (rallies, shotNumberSelection) => {
  if (!rallies) {
    return { 0: {}, 1: {}, 2: {}, 3: {} }
  }
  const stats = Array.from({ length: 4 }, () => ({}))
  for (let r = 0; r < rallies.length; r++) {
    const rally = rallies[r]
    if (Array.isArray(rally.shots)) {
      let shotNumber = 0
      for (let m = 0; m < rally.shots.length; m++) {
        const shot = rally.shots[m]
        if (shot?.resulting_ball_movement) {
          shotNumber += 1
          if (shotNumber === shotNumberSelection || shotNumberSelection === 999) { // 0 means all shots
            // This is now third shot
            if (!stats[shot.player_id][shot.shot_type]) {
              stats[shot.player_id][shot.shot_type] = []
            }
            if (shot.resulting_ball_movement.trajectory) {
              stats[shot.player_id][shot.shot_type].push({
                ...shot.resulting_ball_movement.trajectory,
                // Used for quality calculation
                ...(shot.quality && Object.prototype.hasOwnProperty.call(shot.quality, 'overall')
                  ? { quality: shot.quality.overall }
                  : {})
              })
            }
            // Each rally has only 1 shotNumberSelection [Serve/Return/3rd ..etc] shot, break and iterate to the next rally
            break
          }
        }
      }
    }
  }
  return stats
}

function Avg (props) {
  const { value, label = 'Avg height over net', suffix = '”' } = props
  return value > 0
    ? (
      <div className='body-sm average'>
        {label}:
        <div>
          {value}
          {suffix}
        </div>
      </div>
      )
    : null
}

const getSelectedShots = (val) => {
  if (val === 'all') return ['drive', 'drop', 'lob']
  return [val]
}

const initial3rdShots = Array.from({ length: 4 }, () => 'all')

export const ShotsViewerContext = createContext(null)

export default function SelectedShotsViewer (props) {
  const { insights, video } = useContext(APIContext)
  const { playerIdx, shotNumberSelection, onShotNumberSelectionChange } = props
  const isMobile = useMobileDetect()
  const { updateSearchParams } = useURLParams()
  const playerStats = useRalliesPlayersStats(insights?.rallies)

  const thirdShotStats = playerStats[playerIdx].thirdShotStats

  // 3rd shots
  const [thirdShots, setThirdShots] = useState(initial3rdShots)

  const handleThirdShots = (index, value) => {
    setThirdShots({
      ...thirdShots,
      [index]: value
    })
  }

  // Extract 3rd shot stats from rallies
  const stats = getStats(insights?.rallies, shotNumberSelection)

  const shotQuality = calculateQuality(stats[playerIdx], getSelectedShots(thirdShots[playerIdx]))

  const [shotCounts, setShotCounts] = useState(0)

  const updateShotCount = (count) => {
    setShotCounts(count)
  }

  useEffect(() => {
    if (shotNumberSelection <= 2) {
      handleThirdShots(playerIdx, 'all')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shotNumberSelection])

  return (
    <ShotsViewerContext.Provider value={{ shotCounts, updateShotCount }}>
      <Container className={isMobile && 'mobile'}>
        <div className='viewer-container'>
          <ShotsViewerShotsProvider
            insights={insights}
            playerIdx={playerIdx}
            shotTypesToInclude={new Set(getSelectedShots(thirdShots[playerIdx]))}
            shotNumberInRally={shotNumberSelection}
            video={video}
            viewerProps={{
              camera: null,
              onCameraChanged: null,
              onShotViewRequested: (event) => {
                const muxPlayer = document.querySelector('mux-player')

                if (muxPlayer) {
                  muxPlayer.currentTime = event.timeInSeconds

                  // Play the shot being requested
                  muxPlayer.play()

                  updateSearchParams('t', event.timeInSeconds)
                  updateSearchParams('l', 6)
                }

                const scrollParent = document.getElementById('pbvision')
                if (isMobile) {
                  // note that the header element is not always rendered
                  // for example, there is no header rendered in a
                  // native mobile app's web view
                  if (scrollParent) {
                    // if header is available, scroll up to reveal it
                    scrollParent.scrollTop = 0
                  }
                } else {
                  if (!document.pictureInPictureElement) {
                    if (!enterPictureInPicture(muxPlayer.media) && scrollParent) {
                      // If pip fails, just scroll to the video
                      scrollParent.scrollTop = 0
                    }
                  }
                }
              }
            }}
          />
        </div>
        <div className='viewer-navigation'>
          <div className='selects'>
            <Select
              value={shotNumberSelection}
              className='single-select'
              label=''
              onChange={onShotNumberSelectionChange}
              renderValue={value =>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <SvgIcon color='primary'>
                    <ShotNumberIcon />
                  </SvgIcon>
                  <span>
                    {SHOTS_ARRAY.find(item => item.value === value).label}
                  </span>
                </Box>}
            >
              {SHOTS_ARRAY.map((value, i) => (
                <MenuItem key={i} value={value.value}>{value.label}</MenuItem>
              ))}
            </Select>
            {(shotNumberSelection >= 2 && shotNumberSelection <= 5) &&
              <Select
                value={thirdShots[playerIdx]}
                className='single-select'
                label=''
                onChange={(e) => handleThirdShots(playerIdx, e.target.value)}
              >
                {SHOTS_TYPES.map((type, i) => (
                  <MenuItem value={type.id} key={i}>{type.label}</MenuItem>
                ))}
              </Select>}
          </div>

          {/* <div className='total'>
            {generateTotalShotsLabel(shotCounts, shotNumberSelection, thirdShots[playerIdx])}
          </div> */}
          <div>
            {['drop', 'drive'].includes(thirdShots[playerIdx]) && (
              <Avg
                label='Median Height Above Net'
                suffix='ft'
                value={thirdShotStats[thirdShots[playerIdx]].median_height_above_net}
              />
            )}
            {thirdShots[playerIdx] === 'lob' && (
              <Avg
                label='Median Baseline Distance'
                value={thirdShotStats[thirdShots[playerIdx]].median_baseline_distance}
              />
            )}
          </div>
          <div className='quality-viewer'>
            {shotQuality !== 0 && (
              <>
                <p className='quality-viewer-title'>Shot Quality:</p>
                <Percentile
                  className='quality-viewer-progress'
                  sx={{ color: COLORS['neutral-800'], flex: 1 }}
                  value={shotQuality}
                  suffix='%'
                />
              </>
            )}
            {shotQuality !== 0 &&
              <Tooltip title={THIRD_SHOTS_TOOLTIPS[thirdShots[playerIdx]]}>
                <HelpIcon />
              </Tooltip>}
          </div>
        </div>
      </Container>
    </ShotsViewerContext.Provider>
  )
}

/**
 * Returns a selection of 3rd shots hit by the specified player.
 *
 * @param {object} [insights] the insights object; okay to omit if not yet loaded
 * @param {int} playerId which player's 3rd shots should be extracted
 * @param {Set<string>} shotTypesToInclude which shots to include
 * @param {int} [shotNumberInRally=3] which shot number in the rally to include (e.g., 1 = serves, 2 = returns, etc.)
 * @returns {Array<ShotWithContext>} selected shots and context about them
 */
export function useNthShotsForPlayerWithContext (insights, playerId, shotTypesToInclude, shotNumberInRally = 3) {
  let allShotsIncluded = false
  if (shotNumberInRally === 999) {
    allShotsIncluded = true
  }

  return useFilteredShotsWithContext(insights, shotWithContext => {
    const { shotIdx, shot } = shotWithContext
    let badShot
    if (shot.resulting_ball_movement) {
      /*
       * Remove serves and returns that contain resulting_ball_movement.is_volleyed set to true
       */
      if (shotNumberInRally < 2 && shot.resulting_ball_movement.is_volleyed === true) {
        badShot = true
      } else {
        badShot = isLikelyBadShotData(shotIdx, shot.resulting_ball_movement.trajectory.start, shot.resulting_ball_movement.trajectory.end)
      }
    }

    const isValidShot = !badShot
    const isCorrectShotIndex = shotIdx === shotNumberInRally || allShotsIncluded
    const isCorrectPlayer = shot.player_id === playerId
    const isIncludedShotType = shotTypesToInclude === 'all' || shotTypesToInclude.has(shot.shot_type)

    return (
      isValidShot &&
      isCorrectShotIndex &&
      isCorrectPlayer &&
      isIncludedShotType
    )
  })
}

function SimpleError ({ error, message }) {
  useEffect(() => {
    console.error(error)
  }, [error])
  return (
    <div style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      placeContent: 'center',
      alignItems: 'center',
      background: '#f8ecec',
      color: '#811'
    }}
    >
      <div style={{ maxWidth: '70%' }}>
        {message || 'Sorry! An error occurred while rendering the Shots Viewer.'}
      </div>
    </div>
  )
}

function ShotsViewerShotsProvider ({ insights, playerIdx, shotTypesToInclude, shotNumberInRally, viewerProps, video }) {
  const { updateShotCount } = useContext(ShotsViewerContext)
  const shotsWithContext = useNthShotsForPlayerWithContext(insights, playerIdx, shotTypesToInclude, shotNumberInRally)

  const shots = use3DViewerShots(shotsWithContext)
  useEffect(() => {
    updateShotCount(shots.length)
  }, [shots.length, updateShotCount])

  return shots.length < 1000
    ? (
      <Sentry.ErrorBoundary fallbackRender={({ error }) => <SimpleError error={error} />}>
        <ErrorBoundary
          fallbackRender={({ error }) => <SimpleError error={error} />}
          onError={(error, info) => {
            // Catch and handle WebGL context creation errors
            if (
              error.message.includes('WebGL context could not be created') ||
              error.message.includes('Error creating WebGL context') ||
              // null is not an object (evaluating 't.getShaderPrecisionFormat(t.VERTEX_SHADER,t.HIGH_FLOAT).precision')
              error.message.includes('getShaderPrecisionFormat') ||
              error.message.match(/Could not load (.+)\.(png|glb|svg)/)
            ) {
              return
            }

            // Throw any unexpected errors, reporting to Sentry
            throw error
          }}
        >
          <ShotsViewer
            // key={playerIdx}
            shots={shots.map(normalizeDirection)}
            playerId={playerIdx + 1}
            playbackId={video?.mux?.playbackId}
            {...viewerProps}
          />
        </ErrorBoundary>
      </Sentry.ErrorBoundary>
      )
    : <SimpleError message='Too many shots selected. Please change your selection and try again.' />
}

const Container = styled('div')({
  ...column,
  gap: theme.spacing(2),
  background: COLORS.white,
  color: COLORS.black,
  width: '100%',
  height: 'fit-content',
  '& .viewer-navigation': {
    ...row,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    '& .selects': {
      ...row,
      gap: theme.spacing(2),
      '& .single-select': {
        backgroundColor: COLORS['neutral-050'],
        '& .MuiSelect-select': {
          padding: '8px 30px 8px 8px'
        }
      }
    },
    '& .total': {
      color: COLORS['neutral-500'],
      fontWeight: 'bold',
      fontSize: 14
    },
    '& .average': {
      color: COLORS['neutral-500'],
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      '& > div': {
        marginLeft: '0.25em',
        fontWeight: 600
      }
    },
    '& .quality-viewer': {
      ...row,
      alignItems: 'center',
      gap: theme.spacing(1),
      '& .quality-viewer-title': {
        color: COLORS['neutral-500'],
        fontSize: 14
      },
      '& .percentile': {
        width: 220
      }
    }
  },
  '& .viewer-container': {
    height: 'auto',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadiusSmall,
    border: `1px solid ${COLORS['neutral-300']}`,
    aspectRatio: 1.8,
    overflow: 'hidden'
  },
  '&.mobile': {
    ...column,
    padding: 0,
    '& .viewer-navigation': {
      ...column,
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        width: '100%'
      }
    },
    '& .viewer-container': {
      aspectRatio: 'unset',
      minHeight: '380px',
      '& .ShotsViewer > div': {
        minHeight: '300px',
        height: '300px !important'
      }
    }
  }
})
