/* eslint-disable react/no-array-index-key */
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

import COLORS from '@/utils/colors'

const Toggle = styled(IconButton)({
  '& .icon': {
    color: COLORS['neutral-700'],
    fontSize: '20px',
    padding: '0px !important'
  }
})

const Menu = styled(MuiMenu)({
  minWidth: '220px',
  '& .MuiMenuItem-root': {
    '& .text': {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
      color: COLORS['neutral-700'],
      marginLeft: '14px'
    },
    '& svg': {
      fontSize: '20px'
    }
  }
})

/**
 * Context Menu component
 *
 * @param {object} props - component props
 * @param {string} props.className - toggle class name
 * @param {Array} props.items - { items: [{ icon: React.ReactElement, text: string, onClick: function, disabled: boolean }]}
 * @returns {React.ReactElement} Context Menu component
 */
function ContextMenu (props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = (event) => {
    event?.stopPropagation()
    event?.preventDefault()
    setAnchorEl(null)
  }

  const { items } = props

  return (
    <>
      <Toggle className={props.className} onClick={onClick}>
        <MoreVertIcon className='icon' />
      </Toggle>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => {
          event.stopPropagation()
          closeMenu()
        }}
        onClick={closeMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {items
          ?.filter((item) => !item.hidden)
          .map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={(event) => {
                  closeMenu()
                  event.stopPropagation()
                  event.preventDefault()
                  item.onClick()
                }}
                disabled={!!item.disabled}
              >
                {item.icon}
                <div className='text'>{item.text}</div>
              </MenuItem>
            )
          })}
      </Menu>
    </>
  )
}

export default ContextMenu
