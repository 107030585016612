import { TableSortLabel as MUITableSortLabel, TableBody, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

export const ListViewContainer = styled('div')({
  '& .table-cell': {
    '&.name': {
      maxWidth: 'auto',
      flexGrow: 1,
      padding: '8px 16px !important'
    },
    '&.date': {
      textAlign: 'right',
      width: 'auto',
      padding: '8px 0px !important',
      whiteSpace: 'nowrap'
    },
    '&.duration': {
      width: '1%',
      padding: '0px !important',
      whiteSpace: 'nowrap'
    },
    flexGrow: 1,
    maxWidth: '300px'
  },
  '& .table-heading': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    textUnderlinePosition: 'from-front',
    textDecorationSkipInk: 'none',
    '&.date': {
      textAlign: 'right',
      marginRight: '30px'
    }
  },
  '& .table-text': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '17px',
    textUnderlinePosition: 'from-front',
    textDecorationSkipInk: 'none',
    '&.date': {
      marginRight: '30px'
    },
    '&.duration': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '32px'
    }
  },
  '& .menu-button': {
    padding: '8px 16px !important'
  },
  '&.mobile': {
    '& .menu-button': {
      padding: '8px 16px !important'
    },
    '& .table-cell': {
      '&.name': {
        maxWidth: '200px !important'
      },
      '&.date': {
        maxWidth: '200px !important',
        padding: '0px !important'
      }
    },
    '& .table-heading': {
      '&.date': {
        marginRight: '64px'
      }
    },
    '& .table-text': {
      '&.date': {
        marginRight: '16px'
      }
    }
  }
})

export const TableRowContainer = styled(TableRow)({
  border: 'none',
  borderCollapse: 'collapse',
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  '&.video': {
    '& .red-dot': {
      padding: '3px',
      width: '8px',
      height: '8px',
      backgroundColor: 'red',
      borderRadius: '100%'
    },
    '& .date-section': {
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'pointer',
      '& .MuiFormControl-root': {
        maxWidth: '205px',
        '& .MuiInputBase-root': {
          height: '28px',
          backgroundColor: 'white',
          '& input': {
            fontWeight: '400',
            fontSize: '14px',
            paddingLeft: '9px',
            paddingBottom: '17px'
          },
          '& fieldset': {
            borderColor: '#13940c'
          }
        }
      },
      '& .MuiInputBase-input': {
        fontSize: '16px',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: '24px',
        width: '75px'
      }
    },
    '& .name-section': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      '& .name-container': {
        width: '100%'
      },
      '& .static-name-section': {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        overflow: 'hidden',
        '& .name': {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '19.88px',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none'
        }
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiInputBase-root': {
          height: '28px',
          backgroundColor: 'white',
          '& .input': {
            fontWeight: '400',
            fontSize: '14px',
            paddingLeft: '9px',
            paddingBottom: '17px'
          },
          '& fieldset': {
            borderColor: '#13940c'
          }
        }
      },
      '& .MuiInputBase-input': {
        fontSize: '16px',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: '24px'
      }
    }
  },
  '&.folder': {
    '& .name': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '19.88px',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none'
    }
  },
  '&.mobile': {
    '&.video': {
      '& .date-section': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }
    },
    '&.folder': {
      '& .table-text.date': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .no-date': {
          marginRight: '15px'
        }
      }
    }
  }
})

export const TableSortLabel = styled(MUITableSortLabel)({
  flexDirection: 'row-reverse',
  '& .MuiTableSortLabel-icon': {
    color: COLORS['neutral-500'],
    marginRight: '16px'
  }
})

export const TableBodyContainer = styled(TableBody)({
  '& .MuiTableRow-root:nth-child(even)': {
    backgroundColor: COLORS.white
  },

  '& .MuiTableRow-root:nth-child(odd)': {
    backgroundColor: COLORS['neutral-100']
  }
})
