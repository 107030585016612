import AnaliticsIcon from '@mui/icons-material/AnalyticsOutlined'
import FlagIcon from '@mui/icons-material/FlagOutlined'
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import { styled } from '@mui/material'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
// import { useNavigate, useParams } from 'react-router-dom'

import { PlayerImage } from '@/components/player-image'
import { TagPlayer } from '@/components/tag-player'
import useSafeNavigate from '@/hooks/use-safe-navigate'
import { useURLParams } from '@/hooks/use-url-params'
import { useIsLoggedIn } from '@/store/auth'
import { APIContext } from '@/utils/api'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { formatDate, formatWithTimeConfig } from '@/utils/helper'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'
import theme from '@/utils/theme'

function Header ({ getPlayerName, video }) {
  const team1 = `${getPlayerName(0).split(' ')[0]},${getPlayerName(1).split(' ')[0]}`
  const team2 = `${getPlayerName(2).split(' ')[0]},${getPlayerName(3).split(' ')[0]}`
  return (
    <div className='head'>
      <div className='head-content'>
        <p className='body-sm teams'>
          {team1} vs {team2}
        </p>
        <div className='datetime'>
          <p className='body-xs'>{formatDate(video.userData.gameStartEpoch, formatWithTimeConfig, undefined, true)}</p>
        </div>
      </div>
    </div>
  )
}

const TABS = [
  { id: 'overview', name: 'Home', Icon: <VideocamOutlinedIcon /> },
  { id: 'explore', name: 'Shot Explorer', Icon: <FlagIcon /> },
  { id: 'game-stats', name: 'Game Stats', Icon: <AnaliticsIcon /> },
  { id: 'team-stats', name: 'Team Stats', Icon: <PeopleIcon /> }
]

function Tabs ({ onChange, hasError }) {
  const { vid, tab: activeTab } = useParams()
  const navigate = useSafeNavigate()
  const { nestParamsIntoURL } = useURLParams()

  const goToTab = useCallback((newTabName, replace = false) => {
    const newTabURL = `/video/${vid}/${newTabName || 'overview'}`
    navigate(nestParamsIntoURL(newTabURL), { replace })
  }, [nestParamsIntoURL, navigate, vid])

  function handleTabChange (newTabName) {
    if (hasError) return
    goToTab(newTabName)
    onChange()
  }

  useEffect(() => {
    // If there is an error and some other tab is selected / or if no tab is selected
    // navigate to default 'overview' tab (This will handle both - main tabs and players)
    if ((activeTab !== 'overview' && hasError) || !activeTab) {
      goToTab('overview', true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cls('tabs', hasError && 'has-error')}>
      {TABS.map((tab, i) => (
        <div className={cls('tab', activeTab === tab.id && 'active')} key={i} onClick={() => handleTabChange(tab.id)}>
          {tab.Icon}
          <p className='body-sm'>{tab.name}</p>
        </div>
      ))}
    </div>
  )
}

function Players ({ getPlayerName, aiEngineVersion, onChange, hasError }) {
  const { vid, tabId } = useParams()
  const navigate = useSafeNavigate()
  const { nestParamsIntoURL } = useURLParams()

  const goToTab = useCallback((newTabName) => {
    // scrollTo({ y: 70 }) // idea
    if (hasError) return
    const newTabURL = `/video/${vid}/${newTabName || 'overview'}`
    navigate(nestParamsIntoURL(newTabURL))
  }, [hasError, vid, navigate, nestParamsIntoURL])

  function handlePlayerClick (playerId) {
    goToTab(`player/${playerId}`)
    onChange()
  }

  return (
    <div className={cls('players', hasError && 'has-error')}>
      <p className='body-sm'>Player Stats</p>
      {Array.from({ length: 4 }).map((_, id) => (
        <div className={cls('player', tabId === `${id + 1}` && 'active')} key={id} onClick={() => handlePlayerClick(id + 1)}>
          <PlayerImage playerIdx={id} numberOfImages={1} width={40} height={40} imageWidth={100} previewOnly />
          <p className='name body-sm'>{getPlayerName(id)}</p>
          <TagPlayer vid={vid} playerIdx={id} playerName={getPlayerName(id)} aiEngineVersion={aiEngineVersion} hasOnlyEditIcon />
        </div>
      ))}
    </div>
  )
}

export default function HomeSidebar ({ isOpen, setIsExpanded }) {
  // context stuff
  const { getPlayerName, workflow, video } = useContext(APIContext)
  const aiEngineVersion = workflow?.aiEngineVersion

  const isLoggedIn = useIsLoggedIn()
  const sidebarRef = useRef(null)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const isWebView = isInMobileAppWebview()
  const headerElement = document.querySelector('header')
  // if workflow has error set or if workflow is done but insights isn't ready, then processing failed
  const hasError = workflow.error || (workflow?.noInsights && Boolean(workflow?.epochFinished))

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const headerObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsHeaderVisible(true)
        } else {
          setIsHeaderVisible(false)
        }
      },
      { root: null, threshold: 0 }
    )
    if (headerElement) {
      headerObserver.observe(headerElement)
    }

    return () => {
      if (headerElement) {
        headerObserver.unobserve(headerElement)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerElement])

  function collapseSidebar () {
    setIsExpanded(false)
  }

  useEffect(() => {
    setIsExpanded(isOpen)
  }, [isOpen, setIsExpanded])

  return (
    <>
      <Container className={cls('sidebar', !isOpen && 'collapsed')}>
        <div ref={sidebarRef} className={cls('content', !isHeaderVisible && 'fixed-to-top', isLoggedIn && 'logged-in', isWebView && 'webview')}>
          <Header getPlayerName={getPlayerName} video={video} />
          <Tabs onChange={collapseSidebar} hasError={hasError} />
          <Players getPlayerName={getPlayerName} aiEngineVersion={aiEngineVersion} onChange={collapseSidebar} hasError={hasError} />
        </div>
      </Container>
      <Overlay onClick={collapseSidebar} className={cls(!isOpen && 'collapsed')} />
    </>
  )
}

const Container = styled('div')({
  position: 'fixed',
  width: '240px',
  left: 0,
  bottom: 0,
  top: 0,
  borderRight: `1px solid ${COLORS['neutral-200']}`,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  zIndex: 100,
  transition: 'all 0.5s ease-in-out',
  '& .content': {
    ...column,
    gap: theme.spacing(3),
    backgroundColor: COLORS['neutral-100'],
    height: '100%',
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    overflowY: 'auto'
  },
  '& .head': {
    ...row,
    padding: theme.spacing(2),
    backgroundColor: COLORS['neutral-200'],
    '& .head-content': {
      ...column,
      gap: theme.spacing(1),
      width: '100%',
      '& .teams': {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& .datetime': {
        ...row,
        gap: theme.spacing(2)
      }
    }
  },
  '& .tabs': {
    ...column,
    gap: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '& .tab': {
      ...row,
      color: COLORS['neutral-700'],
      alignItems: 'center',
      gap: theme.spacing(1),
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(1),
      borderTopRightRadius: theme.shape.borderRadiusSmall,
      borderBottomRightRadius: theme.shape.borderRadiusSmall,
      transition: 'all 0.3s ease-in-out',
      cursor: 'pointer',
      '&:hover, &.active': {
        backgroundColor: COLORS['neutral-500'],
        '& p': {
          color: COLORS.white
        },
        '& svg': {
          color: COLORS.white
        }
      }
    }
  },
  '& .players': {
    ...column,
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    '& > p': {
      color: COLORS['neutral-500'],
      marginBottom: theme.spacing(1)
    },
    '& .player': {
      ...row,
      width: '100%',
      paddingRight: theme.spacing(2),
      borderRadius: theme.shape.borderRadiusSmall,
      overflow: 'hidden',
      backgroundColor: COLORS.white,
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover, &.active': {
        backgroundColor: COLORS['neutral-500'],
        '& p': {
          color: COLORS.white
        }
      },
      '& .name': {
        flex: 1,
        paddingLeft: theme.spacing(2),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },
      '& .taggable, & .tagged': {
        '& .player-name': {
          border: 'none',
          borderRadius: theme.shape.borderRadiusSmall,
          height: 30,
          background: 'transparent',
          width: '30px !important',
          padding: 4,
          '&:hover': {
            backgroundColor: COLORS['neutral-200']
          }
        }

      }
    }
  },
  '& .has-error': {
    opacity: '0.5',
    pointerEvents: 'none'
  },
  [theme.breakpoints.up('sm')]: {
    '& .content': {
      paddingTop: 80,
      '&.logged-in': {
        paddingTop: 48
      },
      '&.fixed-to-top': {
        paddingTop: 0
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '&.collapsed': {
      left: '-240px'
    },
    '& .content': {
      paddingTop: 56,
      '&.webview': {
        paddingTop: 0
      }
    }
  }
})

const Overlay = styled('div')({
  display: 'none',
  transition: 'all 0.5s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
    '&.collapsed': {
      display: 'none'
    }
  }
})
