import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export const Container = styled('div')({
  ...row,
  '&.column': {
    ...column,
    gap: 40
  },
  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  [theme.breakpoints.down('md')]: {
    ...column,
    gap: 20,
    '& .buttons': {
      flex: 1,
      margin: '0px !important'
    },
    '& .column': {
      ...row,
      '& .title': {
        flexBasis: '60%',
        justifyContent: 'flex-end',
        marginRight: 16
      }
    }
  },
  '& > div.column': {
    alignItems: 'center',
    flexGrow: 1
  },
  '& .text-container': {
    flex: 1,
    '& .title': {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '31px',
      textUnderlinePosition: 'from-font',
      color: COLORS.black,
      whiteSpace: 'nowrap'
    },
    '& .body': {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      textUnderlinePosition: 'from-font',
      color: COLORS.black
    }
  },
  '& .buttons': {
    ...column,
    flex: 1,
    gap: '16px',
    marginLeft: '32px',
    '& .download-container': {
      ...row,
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS['neutral-200']}`,
      borderRadius: '6px',
      gap: '8px',
      padding: '10px 0px',
      '&:hover': {
        backgroundColor: COLORS['neutral-100']
      },
      '& .icon': {
        fill: COLORS.black
      },
      '& .text': {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        color: COLORS.black,
        whiteSpace: 'nowrap'
      }
    }
  }
})
