import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

function useSafeNavigate () {
  const navigate = useNavigate()
  const safeNavigate = useCallback((path, ...opts) => {
    if (path !== window.location.pathname) {
      navigate(path, ...opts)
    }
  }, [navigate])

  return safeNavigate
}

export default useSafeNavigate
