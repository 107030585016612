import { TableCell } from '@mui/material'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import FolderContextMenu from '../../folder/folder-context-menu'
import { TableRowContainer } from '../container'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { PageContext } from '@/pages/library/context'
import cls from '@/utils/classnames'

export default function Folder (props) {
  const isMobile = useMobileDetect()
  const params = useParams()
  const navigate = useNavigate()
  const { isInPublicLibrary, isBulkSelecting } = useContext(PageContext)

  function onNavigateToFolder (event) {
    if (isBulkSelecting) {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    let link = '/'
    if (isInPublicLibrary) {
      link = `/library/public/${params.uid}/${props.fid}`
    } else {
      link = `/library/${props.fid}`
    }

    if (event.ctrlKey || event.metaKey) {
      window.open(`${window.location.origin}${link}`, '_blank', 'rel=noopener noreferrer')
      return
    }

    navigate(link)
  }

  return (
    <TableRowContainer className={cls('folder', isMobile && 'mobile')} onClick={onNavigateToFolder}>
      <TableCell className='table-cell name'>
        <div className='name'>
          {props?.name}
        </div>
      </TableCell>
      <TableCell className='table-cell date'>
        <div className='table-text date'>
          <div className='no-date'>—</div>
          {isMobile && <FolderContextMenu className='menu-button' folder={props} />}
        </div>
      </TableCell>
      {!isMobile &&
        <TableCell className='table-cell duration'>
          <div className='table-text duration'>
            —
            <FolderContextMenu className='menu-button' folder={props} />
          </div>
        </TableCell>}
    </TableRowContainer>
  )
}
